import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuoteExtensionModule } from './extensions/1039ed8910c0a8299f338747c9f9aa80cc17800d5f14eb9e5b5c67299231bbd4/quote-ui-extension.module';
import { ContactExtensionModule } from './extensions/bb214cf86395d365d413d9648be04bc63673e94010fff7f0ed580e029ec37c09/contact-ui-extension.module';
import { ReviewsUiExtensionModule } from './extensions/bbc58a724d4fc95514034a519f97d18e5e8e33f66f67249543cdf759a344aa18/reviews-ui-extension.module';
import { NewsletterSharedModule } from './extensions/03bc5638f1a04dc615fa94d2312e1032026c1b3337fc57435cb97152ed9e3ef5/newsletter-ui-extension.module';


@NgModule({
    imports: [CommonModule, QuoteExtensionModule, ContactExtensionModule, ReviewsUiExtensionModule, NewsletterSharedModule],
})
export class SharedExtensionsModule {}
