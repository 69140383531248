export const extensionRoutes = [  {
    path: 'extensions/quote',
    loadChildren: () => import('./extensions/1039ed8910c0a8299f338747c9f9aa80cc17800d5f14eb9e5b5c67299231bbd4/quote-ui-lazy.module').then(m => m.QuoteUIModule),
  },
  {
    path: 'extensions/contact',
    loadChildren: () => import('./extensions/bb214cf86395d365d413d9648be04bc63673e94010fff7f0ed580e029ec37c09/contact-ui-lazy.module').then(m => m.ContactUIModule),
  },
  {
    path: 'extensions/product-reviews',
    loadChildren: () => import('./extensions/bbc58a724d4fc95514034a519f97d18e5e8e33f66f67249543cdf759a344aa18/reviews-ui-lazy.module').then(m => m.ReviewsUiLazyModule),
  },
  {
    path: 'extensions/newsletter',
    loadChildren: () => import('./extensions/03bc5638f1a04dc615fa94d2312e1032026c1b3337fc57435cb97152ed9e3ef5/newsletter-ui-lazy.module').then(m => m.NewsletterLazyModule),
  }];
